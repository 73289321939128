import { BigNumber } from '@ethersproject/bignumber';
import { keccak256 } from '@ethersproject/keccak256';
import { getCreate2Address } from '@ethersproject/address';
import { defaultAbiCoder, Interface } from '@ethersproject/abi';
import { WebSocketProvider } from '@ethersproject/providers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import {
  hexZeroPad, hexlify, concat,
} from '@ethersproject/bytes';
import { DETERMINISTIC_ERC6551_REGISTRY_ADDRESS } from '@/common/constants';
import erc6551RegistryAbi from '@/lib/abis/ERC6551Registry.json';

export type NFTMetadata = {
  name: string;
  description: string;
  image: string;
  animation_url?: string;
};

export function abbreviate(str: string, keepLeft = 4, keepRight = 4): string {
  if (!str) return str;
  return str.substring(0, keepLeft) + '...' + str.substring(str.length - keepRight);
}

export function abbreviateAddress(address: string): string {
  return abbreviate(address, 4, 4);
}

export function ultraAbbreviateAddress(address: string): string {
  return abbreviate(address, 2, 4);
}

function escapeRegExp(str: string): string {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

function replaceAll(str: string, find: string, replace: string): string {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function convertSVGToDataURI(raw: string): string {
  let encoded = raw.replace(/\s+/g, ' ');

  // According to Taylor Hunt, lowercase gzips better ... my tiny test confirms this
  encoded = replaceAll(encoded, '%', '%25');
  encoded = replaceAll(encoded, '> <', '><'); // normalise spaces elements
  encoded = replaceAll(encoded, '; }', ';}'); // normalise spaces css
  encoded = replaceAll(encoded, '<', '%3c');
  encoded = replaceAll(encoded, '>', '%3e');
  encoded = replaceAll(encoded, '"', "'");
  encoded = replaceAll(encoded, '#', '%23'); // needed for ie and firefox
  encoded = replaceAll(encoded, '{', '%7b');
  encoded = replaceAll(encoded, '}', '%7d');
  encoded = replaceAll(encoded, '|', '%7c');
  encoded = replaceAll(encoded, '^', '%5e');
  encoded = replaceAll(encoded, '`', '%60');
  encoded = replaceAll(encoded, '@', '%40');

  // charset reportedly not needed ... I need to test before implementing
  return 'data:image/svg+xml;charset=UTF-8,' + encoded;
}

export const mediaIsLandscape = (src: string): boolean => {
  const img = new Image();
  img.src = src;
  const width = img.width;
  let height = img.height;
  height = height + height; // Double the height to get best

  if (width > height) {
    return true;
  }

  return false;
};

export function isJSON (str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * @param url a url that may or may not have a web3 protocol like ar:// or ipfs://
 * @returns url with web3 protocol converted to web2 protocol with https://
 */
export const convertPotentialWeb3ProtocolToWeb2 = (url: string, id?: BigNumber): string => {
  let optimizedUrl = url;

  if (optimizedUrl.includes('ipfs://ipfs/')) {
    optimizedUrl = optimizedUrl.replace('ipfs://ipfs/', 'https://ipfs.io/ipfs/');
  } else if (optimizedUrl.includes('ipfs://')) {
    optimizedUrl = optimizedUrl.replace('ipfs://', 'https://ipfs.io/ipfs/');
  } else if (optimizedUrl.includes('ar://')) {
    optimizedUrl = optimizedUrl.replace('ar://', 'https://arweave.net/');
  } else if (id
    && optimizedUrl.includes('api.opensea.io/api/v1/metadata/')
    && optimizedUrl.includes('{id}')
  ) {
    // OpenSea API URL utilizes EIP1155 swappable `{id}` within the URL.
    // Convert the id to a hexstring before replacing.
    let hexTokenId = id.toHexString();
    hexTokenId = hexTokenId.replace('0x', '');
    optimizedUrl = optimizedUrl.replace('{id}', hexTokenId);
  }

  return optimizedUrl;
};


export function getAccount(
  implementation: string,
  chainId: number,
  tokenContract: string,
  tokenId: string,
  salt: string,
): string {
  const code = getCreationCode(implementation, chainId, tokenContract, tokenId, salt);
  const codeHash = keccak256(code);
  const saltHex = hexZeroPad(BigNumber.from(salt).toHexString(), 32);

  return getCreate2Address(
    DETERMINISTIC_ERC6551_REGISTRY_ADDRESS,
    saltHex,
    codeHash,
  );
}

export function getCreationCode(
  implementation_: string,
  chainId_: number,
  tokenContract_: string,
  tokenId_: string,
  salt_: string,
): Uint8Array {
  const types = ["uint256", "uint256", "address", "uint256"];
  const values = [salt_, chainId_, tokenContract_, tokenId_];
  const creationCode = concat([
    "0x3d60ad80600a3d3981f3363d3d373d3d3d363d73",
    hexlify(implementation_),
    "0x5af43d82803e903d91602b57fd5bf3",
    defaultAbiCoder.encode(types, values),
  ]);

  return creationCode;
}

export const validateFallbackProvider = async (fallbackProvider: string): Promise<void> => {
  const provider = new WebSocketProvider(fallbackProvider);
  const timeOut = new Promise<Response>((resolve, reject) =>
    setTimeout(() => reject(new Error('timeout')), 2000),
  );
  // provider.ready check if connection to provider is valid, timeout after 2s
  await Promise.race([provider.ready, timeOut]);
};

type PollInterface<T> = {
  interval: number;
  maxAttempts: number;
  fetch: () => Promise<T>;
  validate: (result: T) => boolean;
  errorMessage: string;
};

export const poll = <T>(pollConfig: PollInterface<T>): Promise<T> => {
  let attempts = 0;

  const executePoll = async (
    resolve: any, // eslint-disable-line
    reject: any // eslint-disable-line
  ) => {
    let result;
    try {
      result = await pollConfig.fetch();
    } catch (e) {
      return reject(new Error(pollConfig.errorMessage));
    }

    attempts++;

    if (pollConfig.validate(result)) {
      return resolve(result);
    } else if (pollConfig.maxAttempts && attempts === pollConfig.maxAttempts) {
      return reject(new Error(pollConfig.errorMessage));
    } else {
      setTimeout(executePoll, pollConfig.interval, resolve, reject);
    }
  };

  return new Promise(executePoll);
};

export const createAccount = async (
  tokenContract: string,
  tokenId: string,
  chainId: number,
  salt: string | number,
  implementationAddress: string,
): Promise<TransactionResponse> => {
  const initializeABI = [
    {
      inputs: [],
      name: "initialize",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];
  const initInterface = new Interface(initializeABI);
  const initData = initInterface.encodeFunctionData("initialize", []);

  const registryContract = window.ManifoldEthereumProvider.contractInstance(
    DETERMINISTIC_ERC6551_REGISTRY_ADDRESS,
    erc6551RegistryAbi,
    true,
  );

  return await registryContract?.createAccount(
    implementationAddress,
    chainId,
    tokenContract,
    tokenId,
    salt,
    initData,
  );
};
