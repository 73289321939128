const RESOURCE_API: { [key: number]: string } = {
  1: `https://resource.api.manifoldxyz.dev`,
  11155111: `https://sepolia.resource.api.manifoldxyz.dev`,
  10: `https://optimism.resource.api.manifoldxyz.dev`,
  137: `https://matic.resource.api.manifoldxyz.dev`,
  8453: `https://base.resource.api.manifoldxyz.dev`,
};

/**
 * @WARN: DOES NOT HANDLE BEARER/AUTHORIZATION
 */
class APIHelpers {
  static getHeaders (): {'Accept': string; 'Content-Type'?: string;} {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'Authorization': ...
    };
  }

  static fetch(
    network: number,
    path: string,
    options: any = {}, // eslint-disable-line
    query?: string | string[][] | Record<string, string> | URLSearchParams,
  ): Promise<any> { // eslint-disable-line
    return this.sendRequest(network, path, options, 'GET', query);
  }

  static patch(
    network: number,
    path: string,
    options: any = {}, // eslint-disable-line
    query?: string | string[][] | Record<string, string> | URLSearchParams,
  ): Promise<any> { // eslint-disable-line
    return this.sendRequest(network, path, options, 'PATCH', query);
  }

  static post(
    network: number,
    path: string,
    options: any = {}, // eslint-disable-line
    query?: string | string[][] | Record<string, string> | URLSearchParams,
  ): Promise<any> { // eslint-disable-line
    return this.sendRequest(network, path, options, 'POST', query);
  }

  static delete(
    network: number,
    path: string,
    options: any = {}, // eslint-disable-line
    query?: string | string[][] | Record<string, string> | URLSearchParams,
  ): Promise<any> { // eslint-disable-line
    return this.sendRequest(network, path, options, 'DELETE', query);
  }

  static async sendRequest (
    network: number,
    path: string,
    options: any = {}, // eslint-disable-line
    method: string,
    query?: string | string[][] | Record<string, string> | URLSearchParams,
  ): Promise<any> { // eslint-disable-line
    if (!RESOURCE_API[network]) {
      throw new Error('Invalid network');
    }
    try {
      let url = RESOURCE_API[network] + path;

      if (query) {
        const queryString = new URLSearchParams(query).toString();
        url = url + '?' + queryString;
      }

      const requestOptions = {
        method: method,
        headers: this.getHeaders(),
        ...options,
      };

      if (options.body) {
        requestOptions.body = JSON.stringify(options.body);
      }
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw response;
      }
      let result;
      try {
        result = await response.text();
        return JSON.parse(result);
      } catch (e) {
        return result;
      }
    } catch (error: any) { // eslint-disable-line
      let response = error;
      try {
        response = await response.text();
        throw JSON.parse(response);
      } catch (e) {
        throw response;
      }
    }
  }
}

export default APIHelpers;
