import {
  Ref, ref, watchEffect,
} from 'vue';
import { ModuleAccountModule, IAccountModuleState } from '@/store/modules/account.store';

type UseAccount = {
  walletAddress: Ref<IAccountModuleState['walletAddress']>;
  walletENSName: Ref<IAccountModuleState['walletENSName']>;
  network: Ref<IAccountModuleState['network']>;
  avatar: Ref<IAccountModuleState['avatar']>;
  authState: Ref<IAccountModuleState['authState']>;
  isProviderAvailable: Ref<IAccountModuleState['isProviderAvailable']>;
  client: Ref<IAccountModuleState['client']>;
  ownsTheNFT: Ref<IAccountModuleState['ownsTheNFT']>;
  ethereumProvider: Ref<IAccountModuleState['ethereumProvider']>;
};

export function useAccount(): UseAccount {
  const walletAddress = ref<IAccountModuleState['walletAddress']>(ModuleAccountModule.walletAddress);
  const walletENSName = ref<IAccountModuleState['walletENSName']>(ModuleAccountModule.walletENSName);
  const network = ref<IAccountModuleState['network']>(ModuleAccountModule.network);
  const avatar = ref<IAccountModuleState['avatar']>(ModuleAccountModule.avatar);
  const authState = ref<IAccountModuleState['authState']>(ModuleAccountModule.authState);
  const isProviderAvailable = ref<IAccountModuleState['isProviderAvailable']>(ModuleAccountModule.isProviderAvailable);
  const client = ref<IAccountModuleState['client']>(ModuleAccountModule.client);
  const ownsTheNFT = ref<IAccountModuleState['ownsTheNFT']>(ModuleAccountModule.ownsTheNFT);
  const ethereumProvider = ref<IAccountModuleState['ethereumProvider']>(ModuleAccountModule.ethereumProvider);

  watchEffect(() => {
    walletAddress.value = ModuleAccountModule.walletAddress;
    walletENSName.value = ModuleAccountModule.walletENSName;
    network.value = ModuleAccountModule.network;
    avatar.value = ModuleAccountModule.avatar;
    authState.value = ModuleAccountModule.authState;
    isProviderAvailable.value = ModuleAccountModule.isProviderAvailable;
    client.value = ModuleAccountModule.client;
    ownsTheNFT.value = ModuleAccountModule.ownsTheNFT;
    ethereumProvider.value = ModuleAccountModule.ethereumProvider;
  });

  return {
    walletAddress,
    walletENSName,
    network,
    avatar,
    authState,
    isProviderAvailable,
    client: client as Ref<IAccountModuleState['client']>,
    ownsTheNFT,
    ethereumProvider,
  };
}
