export const DATA_WIDGET_VALUE = 'm-6551-display-widget';

// @dev: keep this checksummed! this address is the same on every EVM chain.
export const DETERMINISTIC_ERC6551_REGISTRY_ADDRESS =
  "0x02101dfb77fde026414827fdc604ddaf224f0921";

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';

export const ADDRESS_CHANGED = 'ethereum-address-changed';
export const AUTHENTICATED = 'm-authenticated';
export const UNAUTHENTICATED = 'm-unauthenticated';
