import Vuex from 'vuex';
import { IAccountModuleState } from '@/store/modules/account.store';
import { IStackModuleState } from './modules/stack.store';

export interface IRootState {
  Account: IAccountModuleState;
  Stack: IStackModuleState;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({});
