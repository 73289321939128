import {
  inject, InjectionKey, markRaw, provide, PropType,
} from 'vue';
import { EthereumNetwork, OAuthGrantType } from '@manifoldxyz/frontend-provider-types';
import { TokenFilter } from '@manifoldxyz/endpoint-library';
import { DataClientNFT } from '@/api/models/DataClientNFT';

type PropDefinition = {
  type: any; // eslint-disable-line
  default: any; // eslint-disable-line
};

type Layouts = 'photoalbum' | 'backpack' | 'ntych';

export type Groups = {
  [key:string]: GroupDefinition
}

export interface GroupDefinition {
  items: DataClientNFT[],
  selectable: boolean
  selectableLimit: number
  selectedIndices: number[],
}

type PropsDefinition = Record<string, PropDefinition>;


/*
 * @dev:
 *
 * Adding a new prop? Declare it in two places:
 *   1. MWidgetOptionsProps with Vue type and default value
 *   2. MWidgetProps with TypeScript type
 *
 * Optionally, enforce any new prop-dependency rules in useProvideAll() hook.
 */
export const MWidgetOptionsProps: PropsDefinition = {
  network: {
    type: Number as PropType<EthereumNetwork>,
    default: EthereumNetwork.MAINNET,
  },
  clientId: {
    type: String,
    default: '',
  },
  appName: {
    type: String,
    default: '',
  },
  grantType: {
    type: String as PropType<OAuthGrantType>,
    default: OAuthGrantType.SIGNATURE,
  },
  tokenId: {
    type: String,
    default: undefined,
  },
  tokenContract: {
    type: String,
    default: undefined,
  },
  implementation: {
    type: String,
    default: undefined,
  },
  salt: {
    type: String,
    default: undefined,
  },
  layout: {
    type: String,
    default: 'backpack',
  },
  transferrable: {
    type: Boolean,
    default: false,
  },
  queryFilters: {
    type: Function,
    default: (): TokenFilter[] => [],
  },
  assetLink: {
    type: Function,
    default: (networkId: number, tokenId: string, tokenContract: string): string  => {
      // https://gallery.manifold.xyz(/<NETWORK_NAME>)/<TOKEN_ADDRESS>/<TOKEN_ID>
      return `https://gallery.manifold.xyz${networkId === 1 ? '' : '/' + EthereumNetwork[networkId].toLowerCase()}/${tokenContract}/${tokenId}`;
    },
  },
  onPopulate: {
    type: Function,
    default: (nft: DataClientNFT, groups: Groups): { nft: DataClientNFT; groups: Groups }  => {
      return {
        nft,
        groups,
      };
    },
  },
  onCallToAction: {
    type: Function,
    default: (nft: DataClientNFT, groups: Groups): {
      nft: DataClientNFT,
      groups: Groups,
    } => {
      return {
        nft,
        groups,
      };
    },
  },
  callToActionText: {
    type: String,
    default: '',
  },
  callToActionExecutingText: {
    type: String,
    default: '',
  },
  callToActionExecutingImage: {
    type: String,
    default: '',
  },
  onlyOwnerCanCallToAction: {
    type: Boolean,
    default: true,
  },
  onSecondCallToAction: {
    type: Function,
    default: (nft: DataClientNFT, groups: Groups): {
      nft: DataClientNFT,
      groups: Groups,
    } => {
      return {
        nft,
        groups,
      };
    },
  },
  showSecondCallToAction: {
    type: Boolean,
    default: false,
  },
  secondCallToActionText: {
    type: String,
    default: '',
  },
  secondCallToActionExecutingText: {
    type: String,
    default: '',
  },
  secondCallToActionExecutingImage: {
    type: String,
    default: '',
  },
  hideConnectWidget: {
    type: Boolean,
    default: false,
  },
  showActivateButton: {
    type: Boolean,
    default: false,
  },
  activateButtonText: {
    type: String,
    default: 'Activate',
  },
  showGroupDropdown: {
    type: Boolean,
    default: false,
  },
  activateExecutingText: {
    type: String,
    default: 'Activating Tokenbound Account',
  },
};

/**
 * This is used in entry.ts to properly type check each prop on the way in
 */
export type MWidgetOptionsPropsType
  = keyof typeof MWidgetOptionsProps;

/*
 * Defined once and extended so that the inject() and provide() calls made by
 * the two mixins in this file define all of the same things.
 * Gives all props an actual TypeScript type, in addition to the Vue type.
 */
export interface MWidgetProps {
  fallbackProvider: string | undefined
  network: EthereumNetwork
  tokenId: string
  tokenContract: string
  implementation: string
  salt: string
  layout: Layouts
  transferrable: boolean
  queryFilters: () => TokenFilter[]
  assetLink:  (networkId: number, tokenId: string, tokenContract: string) => string
  onPopulate: (nft: DataClientNFT, groups: Groups) => {
    nft: DataClientNFT,
    groups: Groups,
  };
  onCallToAction: (nft: DataClientNFT, groups: Groups) => {
    nft: DataClientNFT,
    groups: Groups,
  } | void;
  onSecondCallToAction: (nft: DataClientNFT, groups: Groups) => {
    nft: DataClientNFT,
    groups: Groups,
  } | void;
  callToActionText: string
  callToActionExecutingText: string
  callToActionExecutingImage: string
  onlyOwnerCanCallToAction: boolean
  showSecondCallToAction: boolean
  secondCallToActionText: string
  secondCallToActionExecutingText: string
  secondCallToActionExecutingImage: string
  appName: string
  clientId: string
  grantType: OAuthGrantType
  showActivateButton: boolean
  activateButtonText: string
  activateExecutingText: string
  hideConnectWidget: boolean
  showGroupDropdown: boolean
}

/**
 * Referenced in useProvideAll() hook and in useInjectTopLevelProps() hook
 */
export const MWidgetPropsInjectionKey: InjectionKey<MWidgetProps> =
  Symbol('props');

/*
 * A hook you should use to provide all passed in props to the top level component
 * of this widget to its children. After this call is made, all children of that
 * parent component can then use useInjectTopLevelProps() hook to reference those props
 * without needing to prop-weave.
 *
 * ex:
 * const props = defineProps(MWidgetPropsDefinition);
 * useProvideAll(props as MWidgetProps);
 * .. later on in children
 * const { id } = useInjectTopLevelProps()
 */
export const useProvideAll = (props: MWidgetProps): void => {
  if (props.fallbackProvider && !props.network) {
    throw Error('Prop network must be provided if fallbackProvider is provided');
  }

  if (!props.callToActionText) {
    console.warn('If you would like a call to action button to appear, please define `data-on-call-to-action`');
  }

  provide(MWidgetPropsInjectionKey, markRaw(props));
};

/*
 * A hook you can use to get all of the props provided to the top level component
 * of this widget while maintaining type safety.
 *
 * ex:
 * const props = defineProps(MWidgetPropsDefinition);
 * useProvideAll(props as MWidgetProps);
 * .. later on in children
 * const { id } = useInjectTopLevelProps() // id will be typed
 */
export const useInjectTopLevelProps = (): MWidgetProps => {
  const retObj = inject(MWidgetPropsInjectionKey);
  if (!retObj) {
    throw new Error('called useInjectTopLevelProps without useProvideAll call in parent component');
  }

  return retObj;
};
