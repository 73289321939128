import {
  Action, getModule, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { abbreviateAddress } from '@/lib/util';
import ManifoldClient from '@manifoldxyz/oauth-data-client';
import { detectManifoldEthereumProvider } from '@manifoldxyz/frontend-provider-utils';
import { EthereumProvider } from '@manifoldxyz/frontend-provider-types';

export enum AuthState {
  UNKNOWN,
  LOGGED_IN,
  GUEST
}

// @WARN: Never make the default value undefined or it will not be watchable
export interface IAccountModuleState {
  walletAddress: string | null;
  walletENSName: string | null;
  network: number | null;
  avatar: string | null;
  isProviderAvailable: boolean;
  authState: AuthState;
  client: ManifoldClient | null;
  ethereumProvider: typeof EthereumProvider | null;
  ownsTheNFT: boolean;
  // eslint-disable-next-line
  [index: string]: any; // allows for object['property'] syntax
}

@Module({
  name: 'Account',
  store: store,
  dynamic: true,
})
class AccountModule extends VuexModule implements IAccountModuleState {
  public walletAddress: IAccountModuleState['walletAddress'] = null;
  public walletENSName: IAccountModuleState['walletENSName'] = null;
  public network: IAccountModuleState['network'] = null;
  public avatar: IAccountModuleState['avatar'] = '';
  public isProviderAvailable: IAccountModuleState['isProviderAvailable'] = false;
  public authState: IAccountModuleState['authState'] = AuthState.UNKNOWN;
  public client: IAccountModuleState['client'] = null;
  public ownsTheNFT: IAccountModuleState['ownsTheNFT'] = false;
  public ethereumProvider: IAccountModuleState['ethereumProvider'] = null;

  /******** GETS ***********/

  get walletAddressAbbreviated() {
    return abbreviateAddress(this.walletAddress || '');
  }

  /******** MUTATIONS ******/

  @Mutation
  SET_AUTH_STATE(authState: IAccountModuleState['authState']): void {
    this.authState = authState;
  }

  @Mutation
  SET_WALLET_ADDRESS(address: IAccountModuleState['walletAddress']): void {
    this.walletAddress = address;
  }

  @Mutation
  SET_WALLET_ENS_NAME(ensName: IAccountModuleState['walletENSName']): void {
    this.walletENSName = ensName;
  }

  @Mutation
  SET_NETWORK(network: IAccountModuleState['network']): void {
    this.network = network;
  }

  @Mutation
  SET_AVATAR(avatar: IAccountModuleState['avatar']): void {
    this.avatar = avatar;
  }

  @Mutation
  SET_CLIENT(client: IAccountModuleState['client']): void {
    this.client = client;
  }

  @Mutation
  SET_OWNS_THE_NFT(ownsTheNFT: IAccountModuleState['ownsTheNFT']): void {
    this.ownsTheNFT = ownsTheNFT;
  }

  @Mutation
  SET_ETHEREUM_PROVIDER(provider: IAccountModuleState['ethereumProvider']): void {
    this.ethereumProvider = provider;
  }

  @Mutation
  FETCH_ACCOUNT_FAILURE(): void {
    console.warn('FETCH_ACCOUNT_FAILURE');
  }

  @Mutation
  SET_PROVIDER_AVAILABLE(isProviderAvailable: IAccountModuleState['isProviderAvailable']): void {
    this.isProviderAvailable = isProviderAvailable;
  }

  @Mutation
  RESET_ACCOUNT_STATE(): void {
    this.walletAddress = null;
    this.walletENSName = null;
    this.avatar = '';
    this.isProviderAvailable = false;
    this.authState = AuthState.GUEST;
  }

  /******** ACTIONS ******/

  @Action
  async refreshProvider(): Promise<void> {
    const ethereumProvider = await detectManifoldEthereumProvider();

    const provider = this.ethereumProvider?.provider();
    const signingProvider = this.ethereumProvider?.provider(true);

    if (signingProvider && provider !== signingProvider) {
      this.SET_PROVIDER_AVAILABLE(false);
      this.SET_ETHEREUM_PROVIDER(null);
    } else {
      this.SET_PROVIDER_AVAILABLE(!!provider);
      this.SET_ETHEREUM_PROVIDER(ethereumProvider);
    }
  }

  @Action
  setNetwork(network: number): void {
    this.SET_NETWORK(network);
  }

  @Action
  setClient(client: ManifoldClient): void {
    this.SET_CLIENT(client);
  }

  @Action
  setOwnsTheNFT(ownsTheNFT: IAccountModuleState['ownsTheNFT']): void {
    this.SET_OWNS_THE_NFT(ownsTheNFT);
  }

  @Action
  async detectENSName(): Promise<void> {
    const selectedENSName = this.ethereumProvider?.selectedENSName();

    if (selectedENSName) {
      this.SET_WALLET_ENS_NAME(selectedENSName);
    } else {
      this.SET_WALLET_ENS_NAME(null);
    }
  }

  @Action
  async setWalletAddress(wallet: string): Promise<void> {
    this.SET_WALLET_ADDRESS(wallet);
  }

  @Action
  detectChainChanged(): void {
    if (this.ethereumProvider && this.ethereumProvider.chainIsCorrect()) {
      const network = this.ethereumProvider.chainId()
        || this.ethereumProvider.network();

      if (network) {
        this.SET_NETWORK(network);
      }
    } else {
      this.SET_NETWORK(0);
      this.SET_AUTH_STATE(AuthState.GUEST);
      this.ethereumProvider?.disconnect();
    }
  }

  // @WARN: this doesnt do anything really
  @Action
  fetchAccount(): void {
    try {
      this.SET_AUTH_STATE(AuthState.LOGGED_IN);
    } catch (error) {
      console.warn('Fetch account error', error);
      this.FETCH_ACCOUNT_FAILURE();
      throw error;
    }
  }

  @Action
  logout(): void {
    this.deAuthenticate();
  }

  @Action
  setAuthState(authState: IAccountModuleState['authState']): void {
    this.SET_AUTH_STATE(authState);
  }

  @Action
  deAuthenticate(): void {
    this.resetAccountState();
  }

  @Action
  resetAccountState(): void {
    this.RESET_ACCOUNT_STATE();
  }
}

export const ModuleAccountModule = getModule(AccountModule);
