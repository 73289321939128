import {
  Ref, ref, watchEffect,
} from 'vue';
import { ModuleNFTModule, INFTModuleState } from '@/store/modules/nft.store';

type UseNFTType = {
  groups: Ref<INFTModuleState['groups']>;
  ownerNFT: Ref<INFTModuleState['ownerNFT']>;
  account: Ref<INFTModuleState['account']>;
  tokenboundAccountActivated: Ref<INFTModuleState['tokenboundAccountActivated']>;
};

export function useNFT(): UseNFTType {
  const groups = ref<INFTModuleState['groups']>(ModuleNFTModule.groups);
  const ownerNFT = ref<INFTModuleState['ownerNFT']>(ModuleNFTModule.ownerNFT);
  const account = ref<INFTModuleState['account']>(ModuleNFTModule.account);
  const tokenboundAccountActivated = ref<INFTModuleState['tokenboundAccountActivated']>(ModuleNFTModule.tokenboundAccountActivated);

  watchEffect(() => {
    groups.value = ModuleNFTModule.groups;
    ownerNFT.value = ModuleNFTModule.ownerNFT;
    account.value = ModuleNFTModule.account;
    tokenboundAccountActivated.value = ModuleNFTModule.tokenboundAccountActivated;
  });

  return {
    groups,
    ownerNFT,
    account,
    tokenboundAccountActivated,
  };
}
