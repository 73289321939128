<template>
  <div class="modal">
    <div class="modal-content">
      <slot name="content" />
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
// @TODO: This should be not scope, prefaced with .m-, and maybe even come from our reset.

@import '@/scss/breakpoints.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: 1000000000;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.4);
  animation-name: blur;
  animation-duration: 1s;
  border-radius: var(--manifold-theme--border-radius);
}

.modal-content {
  width: 24rem;
  display: flex;
  max-height: 90%;
  position: relative;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  text-align: center;
  overflow: auto;

  @include large {
    min-width: 90%;
    max-width: 90%;
  }
}

@keyframes blur {
  from {
    backdrop-filter: blur(0);
  }

  to {
    backdrop-filter: blur(12px);
  }
}

</style>
