import {
  Action, getModule, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { Groups } from '@/exports/MWidgetProps';
import { DataClientNFT } from '@/api/models/DataClientNFT';

export enum AuthState {
  UNKNOWN,
  LOGGED_IN,
  GUEST
}

// @WARN: Never make the default value undefined or it will not be watchable
export interface INFTModuleState {
  groups: Groups;
  ownerNFT: DataClientNFT;
  account: string;
  tokenboundAccountActivated: boolean;
  [index: string]: any; // eslint-disable-line
}

@Module({
  name: 'NFT',
  store: store,
  dynamic: true,
})
class NFTModule extends VuexModule implements INFTModuleState {
  public groups: INFTModuleState['groups'] = {
    'all': {
      items: [],
      selectable: false,
      selectableLimit: 0,
      selectedIndices: [],
    },
  };
  public ownerNFT: INFTModuleState['ownerNFT'] = {
    contractAddress: '',
    tokenId: '',
    ownerAddress: '',
    name: '',
    image: '',
    spec: '',
    count: '',
    metadata: {
      image: undefined,
      animation_url: undefined,
    },
  };
  public account: INFTModuleState['account'] = '';
  public tokenboundAccountActivated: INFTModuleState['tokenboundAccountActivated'] = false;

  /******** GETTERS ***********/


  /******** MUTATIONS ******/

  @Mutation
  SET_GROUPS(groups: INFTModuleState['groups']): void {
    this.groups = groups;
  }

  @Mutation
  SET_OWNER_NFT(ownerNFT: INFTModuleState['ownerNFT']): void {
    this.ownerNFT = ownerNFT;
  }

  @Mutation
  SET_ACCOUNT(account: INFTModuleState['account']): void {
    this.account = account;
  }

  @Mutation
  SET_6551_ACCOUNT_ACTIVATED(tokenboundAccountActivated: INFTModuleState['tokenboundAccountActivated']): void {
    this.tokenboundAccountActivated = tokenboundAccountActivated;
  }

  /******** ACTIONS ******/

  @Action
  setGroups(groups: INFTModuleState['groups']): void {
    this.SET_GROUPS(groups);
  }

  @Action
  setOwnerNFT(ownerNFT: INFTModuleState['ownerNFT']): void {
    this.SET_OWNER_NFT(ownerNFT);
  }

  @Action
  setAccount(account: INFTModuleState['account']): void {
    this.SET_ACCOUNT(account);
  }

  @Action
  set6551AccountActivated(tokenboundAccountActivated: INFTModuleState['tokenboundAccountActivated']): void {
    this.SET_6551_ACCOUNT_ACTIVATED(tokenboundAccountActivated);
  }
}

export const ModuleNFTModule = getModule(NFTModule);
